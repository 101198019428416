.marker {
  background-image: url("./mapIcon.svg");
  background-repeat: no-repeat;
}
/* .marker2 {
  background-image: url("./mapIcon.svg");
  background-repeat: no-repeat;
}
.google-map {
  border-radius: 12px;
}
.text-shadow {
  text-shadow: 2px 2px #ffffff;
} */
