.webkitHighlight {
  -webkit-tap-highlight-color: transparent;
}

/* for custom scroll bar  */
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.custom-scroll::-moz-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
.custom-scroll:hover::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
  margin: 10px 20px;
}
.custom-scroll:hover::-moz-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
  margin: 10px 20px;
}
/* Handle */
.custom-scroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 152, 51, 1);
  border-radius: 3px;
}
.custom-scroll:hover::-moz-scrollbar-thumb {
  background-color: rgba(0, 152, 51, 1);
  border-radius: 3px;
}
