.date-range-picker-input {
  align-items: center;
  position: relative;
  display: inline-block;
}

.date-label {
  margin-right: 10px;
  font-size: 14px;
}

.date-input-wrapper {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  justify-content: space-between;
}

.date-input {
  border: none;
  font-size: 15px;
  margin-left: 8px;
  font-weight: 600;
  padding: 5px 2px;
  border-radius: 20px;
  width: 160px;
  text-align: center;
}

.date-input:focus {
  outline: none;
}

.date-range-picker-popover {
  position: absolute;
  top: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0px 12px #049d3e69;
  border-radius: 4px;
  left: -100px;
  right: -30px;
  margin-top: 15px;
  z-index: 1000;
}
