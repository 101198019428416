@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}
body::-moz-scrollbar {
  width: 7px;
  height: 6px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 152, 51, 1);
  border-radius: 9px;
}
body::-moz-scrollbar-thumb {
  background-color: rgba(0, 152, 51, 1);
  border-radius: 9px;
}

.hideReviews {
  display: none;
}
